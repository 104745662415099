import * as React from 'react';

import {CrossFadedImageBackground} from '../background/cross-faded-image-background';
import Footer from '../footer';
import PageContainer from '../page-container';
import PageContent from '../page-content';

import './index.css';

const FIVE_SECONDS_IN_MS = 5000;

const BACKGROUNDS: string[] = [
  require('../../static/img/flatiron_building.jpg'),
  require('../../static/img/tv.jpg'),
  require('../../static/img/strawberries.jpg'),
  require('../../static/img/selfie.jpg'),
  require('../../static/img/flare.jpg'),
]

interface State {
  modifier: number;
  prevBackground: string | null;
  currBackground: string;
}

class HomePage extends React.Component<{}, State> {
  interval: NodeJS.Timeout | null;

  constructor(props: {}) {
    super(props);
    this.interval = null;
    this.state = {
      modifier: 0,
      prevBackground: null,
      currBackground: BACKGROUNDS[0],
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(prevState => {
        const newModifier = (prevState.modifier + 1) % BACKGROUNDS.length;
        return {
          modifier: newModifier,
          prevBackground: prevState.currBackground,
          currBackground: BACKGROUNDS[newModifier],
        };
      });
    }, FIVE_SECONDS_IN_MS);
  }

  componentWillUnmount() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  }

  render() {
    return (
      <PageContainer className="home-page">
        <CrossFadedImageBackground currSrc={this.state.currBackground} prevSrc={this.state.prevBackground} />
        <PageContent isHomePage>
          <div className="home-page__body">
            <div className="home-page__title">hugh han</div>
            <Footer className="home-page__footer" currentPage="home" />
          </div>
        </PageContent>
      </PageContainer>
    );
  }
}

export default HomePage;
