import React from 'react';

import './index.css';

interface Props {
  src: string;
  onChange?: () => void;
  onLoad?: () => void;
}

export const ImageBackground: React.FC<Props> = (props: Props) => {
  return <div className="background">
    <img src={props.src} onChange={props.onChange} onLoad={props.onLoad} />
  </div>
}

export default ImageBackground;
