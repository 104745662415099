import * as React from 'react';
import classNames from 'classnames';

import './index.css';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export const PageContainer: React.FC<Props> = (props: Props) => {
  return (
    <div className={classNames('page-container', props.className)}>
      {props.children}
    </div>
  );
};

export default PageContainer;
