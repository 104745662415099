export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const SHORT_MONTH_LENGTH = 3;

export const CSS_FADE_IN_TIMEOUT_IN_MS = 300;
export const CSS_CROSS_FADE_TIMEOUT_IN_MS = 500;
