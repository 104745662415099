import * as React from 'react';
import classNames from 'classnames';

import './index.css';

interface Props {
  className?: string;
  children?: React.ReactNode;
  isHomePage?: boolean;
}

export const PageContent: React.FC<Props> = (props: Props) => {
  return (
    <div className={classNames(props.isHomePage ? 'home-page-content' : 'page-content', props.className)}>
      {props.children}
    </div>
  );
}

PageContent.defaultProps = {
  isHomePage: false,
};

export default PageContent;