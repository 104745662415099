import React from 'react';
import {CSSTransition} from 'react-transition-group';

import {CSS_CROSS_FADE_TIMEOUT_IN_MS} from '../../common/constants';

import ImageBackground from './image-background';
import ResponsiveImageBackground from './responsive-image-background';

import './index.css';

interface Props {
  prevSrc: string | null;
  currSrc: string;
}

interface State {
  backgroundLoaded: boolean;
}

export class CrossFadedImageBackground extends React.Component<Props, State> {

  state = {
    backgroundLoaded: false,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.currSrc !== prevProps.currSrc && this.state === prevState) {
      this.setState({backgroundLoaded: false});
    }
  }

  render() {
    return (
      <React.Fragment>
        <ResponsiveImageBackground src={this.props.currSrc} onLoad={this.setBackgroundLoaded} />
        <CSSTransition
          in={this.shouldDisplayPlaceholderBackground()}
          classNames="background-cross-fade"
          timeout={CSS_CROSS_FADE_TIMEOUT_IN_MS} 
        >
          <ImageBackground src={this.props.prevSrc!}/>
        </CSSTransition>
      </React.Fragment>
    )
  }

  private shouldDisplayPlaceholderBackground = () => {
    return !this.state.backgroundLoaded && this.props.prevSrc !== null;
  }

  private setBackgroundLoaded = () => {
    this.setState({backgroundLoaded: true});
  }
}
