import * as React from 'react';
import {Link} from 'react-router-dom';

import Footer from '../footer';
import PageBody from '../page-body';
import PageContainer from '../page-container';
import PageContent from '../page-content';
import Header from '../header';

import './index.css';

export const AboutPage: React.FC<{}> = () => {
  return (
    <PageContainer>
      <PageContent>
        <Header text="about" />
        <PageBody>
          <p>Hi, I’m Hugh.</p>
          <p>
            I'm a recent graduate from Johns Hopkins University where I studied computer science
            and applied math. I live in New York, where I work as an engineer at TikTok. I
            previously worked on a few open source projects: most notably a{' '}
            <a href="https://github.com/mypyc/mypyc" target="_blank">
              Python compiler
            </a>
            ,{' '}
            <a href="https://github.com/mongodb/mongo" target="_blank">
              sharded database
            </a>
            , and university{' '}
            <a href="https://github.com/noahpresler/semesterly" target="_blank">
              course-scheduling platform
            </a>
            .
          </p>
          <p>
            In my free time I like dancing, shooting film photography, and checking off films from
            {' '}
            <a href="https://letterboxd.com/hughhan/watchlist" target="_blank">
              my Letterboxd watchlist
            </a>
            .
          </p>
          <p>
            If you would like to get to know me, please <Link to="/contact/">reach out</Link>.
            There are rarely things that I find as meaningful as genuine connections with other
            people.
          </p>
          <p>Thanks for stopping by!</p>
        </PageBody>
        <Footer currentPage="about" />
      </PageContent>
    </PageContainer>
  );
};

export default AboutPage;