import React from 'react';
import {CSSTransition} from 'react-transition-group';

import {CSS_FADE_IN_TIMEOUT_IN_MS} from '../../common/constants';

import ColorBackground from './color-background';
import ImageBackground from './image-background';

import './index.css';

const FALLBACK_BACKGROUND_COLOR = '#A0A5A9';

interface Props {
  src: string;
  onLoad?: () => void;
}

interface State {
  backgroundLoaded: boolean;
}

export class ResponsiveImageBackground extends React.Component<Props, State> {
  state = {
    backgroundLoaded: false,
  };

  render() {
    return (
      <React.Fragment>
        <ImageBackground src={this.props.src} onLoad={this.onLoad} />
        <CSSTransition
          classNames="background-fade-in"
          in={!this.state.backgroundLoaded}
          timeout={CSS_FADE_IN_TIMEOUT_IN_MS}
        >
          <ColorBackground color={FALLBACK_BACKGROUND_COLOR} />
        </CSSTransition>
      </React.Fragment>
    );
  }

  private setBackgroundLoaded = (): void => {
    if (!this.state.backgroundLoaded) {
      this.setState({backgroundLoaded: true});
    }
  }

  private onLoad = (): void => {
    this.setBackgroundLoaded();
    if (this.props.onLoad) {
      this.props.onLoad();
    }
  }
}

export default ResponsiveImageBackground;
