import React from 'react';

import './index.css';

interface Props {
  color: string;
}

export const ColorBackground: React.FC<Props> = (props: Props) => {
  return <div className="background" style={{backgroundColor: props.color}} />
}

export default ColorBackground;
